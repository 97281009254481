import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import ChangeColors from "./ChangeColors";
import {LandingPage} from "../components/LandingPage";
import ColorValues from "./ColorValues";
import "./CustomSlider.scss";


export interface Props {
    color1: string;
    color2: string;
    textType: number;
    categoryType: number;
    category: number;
    year: number;
}






let color1 = '';
let color2 = '';

const changeColor = new ChangeColors();



const colors = new ColorValues();
export default class CustomSlider extends React.Component<Props, { newColor: string}> {
    constructor(props: Props) {

        super(props);
        this.state = {
            newColor: changeColor.changeColor(this.props.color1,this.props.color2,(50/100)),
        }
        this.valuetext = this.valuetext.bind(this);
    }

    newColor = '';

    valuetext(event: any, value: any) {
        this.newColor = changeColor.changeColor(this.props.color1,this.props.color2,(value/100));
        this.setState({newColor: this.newColor});
        return value;
    }



    render() {
        return (
    <div className={'Slider-With-Background'}>

                <div className={"sliderwrapper"}>
                    <Slider className={"SliderArea"}
                            style={{}}
                            min={1}
                            max={100}
                            orientation="vertical"
                            onChange={this.valuetext}
                            defaultValue={50}
                            aria-labelledby="vertical-slider"
                            valueLabelDisplay={"auto"}


                    />


            </div>
            <div className="background" style={{backgroundColor: this.state.newColor}}>
                <LandingPage colorA={this.props.color1} colorB={this.props.color2} textType={this.props.textType} categoryType={this.props.categoryType} category={this.props.category} year={this.props.year}/>
            </div>
    </div>
        );
    }
}



