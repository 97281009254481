import React from "react";
import ReactTooltip from "react-tooltip";
import './YearComponent.scss';




export interface Props {
    studienjahr: number,
    zahlA: number,
    prozentA: number,
    zahlB: number,
    prozentB: number,
    gesamtZahl: number,
    color: string

}

export default class YearComponentEducation extends React.Component<Props>{
    constructor(props: Props) {
        super(props);
    }




    render() {
        return (

            <div className={'one-year'} style={{backgroundColor: this.props.color}}>
                <div className={'studyyear'}>
                    {this.props.studienjahr}

                </div>
                <a className={'tooltip-year'}
                   data-for={"main"}
                   data-tip={"Bildungsinländisch Prozent: " + this.props.prozentA + "%" +
                   "<br/>Bildungsausländisch Prozent: " + this.props.prozentB + "%" +
                   "<br/>Bildungsinländisch: " + this.props.zahlA +
                   "<br />Bildungsausländisch: " + this.props.zahlB +
                   "<br/>Gesamt: " + this.props.gesamtZahl +
                   "<br/>Farbe: " + this.props.color
                   }
                   data-iscapture={"true"}>

                    <ReactTooltip
                        id={"main"}
                        multiline={true}
                    />

                </a>

            </div>
        );
    }
}
