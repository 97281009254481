import React, {useState} from 'react';


const checkText = (textType: number) => {
  switch (textType) {
    case 0:
      return 'Was schätzt du?';
    case 1:
      return 'Geschlecht';
    case 2:
      return 'Nationalität';
    case 3:
      return 'Bildungsherkunft';
    case 4:
      return 'Du willst dazugehören?';
    case 5:
      return 'Hochschulzugangsberechtigung'
  }
};





export interface Props {
  textType: number;
}

export class TextHeader extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }


  render() {
    return (
        <div>
          <h1>{checkText(this.props.textType)}</h1>
        </div>
    );
  }
}


export default TextHeader;
