import React from "react";
import TextHeader from "./TextComponents/Text-Header";
import {TextBlock} from "./TextComponents/TextBlock";
import ColorLegend from "../shared/ColorLegend";
import CustomSlider from "../shared/CustomSlider";
import './LandingPage.scss';
import ColorValues from "../shared/ColorValues";
import {type} from "os";
import DetailPage from "./DetailPage";
import CompareView from "./CompareView";
import TimelineComponent from "./TimelineComponent";


export interface Props {
    colorA: string;
    colorB: string;
    textType: number;
    categoryType: number;
    category: number;
    year: number;
}
const colors = new ColorValues();
export class LandingPage extends React.Component<Props, { colorA: string, colorB: string }> {
    constructor(props: Props) {
        super(props);

    }
    categories: string[] =[];
    getHzbCategories() {
        if (this.props.categoryType === 3) {
            this.categories[0] = 'Allg. HZB';
            this.categories[1] = 'FH-Reife';
            this.categories[2] = 'Beruf';
            this.categories[3] = 'Ausl. HZB';
        }
    }

    smoothScroll(){

    }

    checkHZB() {
        if (this.props.categoryType === 3) {

            return (
                <div className={'hzb'} style={{backgroundColor: '#264653'}}>
                    <div className={'landing-hzb'}>
                <h1 className={'header-hzb'}>Du möchtest dazugehören?</h1>
                    <div className={'text-hzb first-line'}>Mit welcher Hochschulzugangsberechtigung</div>
                    <div className={'text-hzb second-line'}>würdest du Medieninformatik studieren?</div>
                        <div className={'category-buttons'}>
                            <a href={'#detail-hzb'} className={'abitur link'} >
                                <div className={'first-line-category'}>
                                    Allgemeine HZB.
                                </div>
                                <div className={'second-line-category'}>
                                    (Abitur)
                                </div>
                            </a>

                            <a href={'#detail-hzb'} className={'fachabi link'} >
                                <div className={'first-line-category'}>
                                    Fachhochschulreife
                                </div>
                                <div className={'second-line-category'}>
                                    (Fachabitur)
                                </div>
                            </a>
                            <a href={'#detail-hzb'} className={'beruf link'} >
                                Beruflich Qualifiziert
                            </a>
                            <a href={'#detail-hzb'} className={'ausland link'}>
                                <div className={'first-line-category'}>
                                    ausländische HZB./
                                </div>
                                <div className={'second-line-category'}>
                                    Studienkolleg
                                </div>

                            </a>
                        </div>
                    </div>
                    <div className={'detail-hzb'} id={'detail-hzb'}>
                    <DetailPage categories={this.categories} color1={this.props.colorA} color2={this.props.colorB} studentStatus={0} categoryType={3}></DetailPage>
                    </div>
                    <div className={'compare-hzb'}>
                       <TimelineComponent categoryType={3} studentStatus={0} categoryHeader={0}></TimelineComponent>
                    </div>

                </div>

            )
        }
    }

    render() {
        return (

            <div>
                {this.checkHZB()}
            </div>

        );
    }
}



