import React from "react";
import ReadData from "./../../ReadData";
import YearComponentGender from "./YearDetailComponents/YearComponentGender";
import YearComponentEducation from "./YearDetailComponents/YearComponentEducation";
import YearComponentNationality from "./YearDetailComponents/YearComponentNationality";
import ColorValues from "../shared/ColorValues";
import ChangeColors from "../shared/ChangeColors";
import './DetailPage.scss'
import YearComponentHzb from "./YearDetailComponents/YearComponentHzb";
import ColorLegend from "../shared/ColorLegend";



const dataService = new ReadData();


export interface Props {
    categories: string[];
    color1: string;
    color2: string;
    studentStatus: number;
    categoryType: number;
}


const colors = new ColorValues();
const changeColor = new ChangeColors();

export default class DetailPage extends React.Component<Props, any>{
    constructor(props: Props) {
        super(props);
        this.state = {
            chartData: [],
            color: [],
        };

    }


    componentDidMount() {
        if (this.props.studentStatus === 0) {

            switch (this.props.categoryType) {

                case 0:
                    dataService.getStudentbeginnersAllGender()
                        .then((data) => {
                            for (var i in data){
                                data[i].color = this.mixColorGender(data[i].weiblichProzent);
                            }

                            this.setState({chartData: data})
                        }, (error) => console.log(error));
                    break;

                case 1:
                    dataService.getStudentbeginnersAllNationality()
                        .then(data => {
                            for (var i in data){
                                data[i].color = this.mixColorNationality(data[i].auslaendischProzent);
                            }
                            this.setState({chartData: data})
                        })
                        .catch(e => console.log(e))
                    break;

                case 2:
                    dataService.getStudentbeginnersAllEducationBackground()
                        .then(data => {
                            for (var i in data){
                                data[i].color = this.mixColorEducation(data[i].auslaendischProzent);
                            }
                            this.setState({chartData: data})
                        })
                        .catch(e => console.log(e))
                    break;

                case 3:
                    dataService.getAllUniversityQualification()
                        .then(data => {
                            for (var i in data){
                                data[i].color = this.getColorHzb(data[i].studienjahr);
                            }
                            this.setState({chartData: data})
                        })
                        .catch(e => console.log(e));
                    break;
            }
        }
        else if (this.props.studentStatus === 1) {
            switch (this.props.categoryType){
                case 0:
                    dataService.getStudentsAllGender()
                        .then(data => {
                            for (var i in data){
                                data[i].color = this.mixColorGender(data[i].weiblichProzent);
                            }
                            this.setState({chartData: data})
                        })
                        .catch(e => console.log(e))
                    break;

                case 1:
                    dataService.getStudentsAllNationality()
                        .then(data => {
                            for (var i in data){
                                data[i].color = this.mixColorNationality(data[i].auslaendischProzent);
                            }
                            this.setState({chartData: data})
                        })
                        .catch(e => console.log(e))
                    break;

                case 2:
                    dataService.getStudentsAllEducationBackground()
                        .then(data => {
                            for (var i in data){
                                data[i].color = this.mixColorEducation(data[i].auslaendischProzent);
                            }
                            this.setState({chartData: data})
                        })
                        .catch(e => console.log(e))
                    break;
            }
        }
}

    mixColorGender = (prozent: number) =>{
        if (prozent === 100) {
            prozent--;
            return changeColor.changeColor(colors.baseColorMen, colors.baseColorWomen, (prozent/100) );
        }
        else if (prozent === 0) {
            prozent++;
            return changeColor.changeColor(colors.baseColorMen, colors.baseColorWomen, (prozent/100) );
        }
        else {
            return changeColor.changeColor(colors.baseColorMen, colors.baseColorWomen, (prozent/100) );
        }
    }

    mixColorNationality = (prozent: number) =>{
        if (prozent === 100) {
            prozent--;
            return changeColor.changeColor(colors.baseColorNationalityDomestic, colors.baseColorNationalityForeign, (prozent/100) );
        }
        else if (prozent === 0) {
            prozent++;
            return changeColor.changeColor(colors.baseColorNationalityDomestic, colors.baseColorNationalityForeign, (prozent/100) );
        }
        else {
            return changeColor.changeColor(colors.baseColorNationalityDomestic, colors.baseColorNationalityForeign, (prozent/100) );
        }

    }

    getColorHzb = (year: number) => {
        let color: string = '';
        switch (year) {
            case 2013:
                return color = colors.hzbColorYear2013;
            case 2014:
                return color = colors.hzbColorYear2014;
            case 2015:
                return color = colors.hzbColorYear2015;
            case 2016:
                return color = colors.hzbColorYear2016;
            case 2017:
                return color = colors.hzbColorYear2017;
            case 2018:
                return color = colors.hzbColorYear2018;
            case 2019:
                return color = colors.hzbColorYear2019;
        }
        return color;
    }

    mixColorEducation = (prozent: number) =>{
        if (prozent === 100) {
            prozent--;
            return changeColor.changeColor(colors.baseColorEducationDomestic, colors.baseColorEducationForeign, (prozent/100) );
        }
        else if (prozent === 0) {
            prozent++;
            return changeColor.changeColor(colors.baseColorEducationDomestic, colors.baseColorEducationForeign, (prozent/100) );
        }
        else {
            return changeColor.changeColor(colors.baseColorEducationDomestic, colors.baseColorEducationForeign, (prozent/100) );
        }

    }


    render() {
        const sta = this.state.chartData;
        if (this.props.studentStatus == 0) {
            switch (this.props.categoryType) {
                case 0:
                    return (
                        <div>

                            <div className={'Detail-View'}>
                                <StudentbeginnersGender jahre={sta}/>
                            </div>
                            <div className={'Color-Legend'}>
                                <ColorLegend categories={this.props.categories} colorA={this.props.color1}
                                             colorB={this.props.color2}
                                             categoryType={this.props.categoryType}></ColorLegend>
                            </div>
                        </div>
                    );
                case 1:
                    return (
                        <div>

                            <div className={'Detail-View'}>
                                <StudentbeginnersNationality jahre={sta}/>
                            </div>
                            <div className={'Color-Legend'}>
                                <ColorLegend categories={this.props.categories} colorA={this.props.color1}
                                             colorB={this.props.color2}
                                             categoryType={this.props.categoryType}></ColorLegend>
                            </div>
                        </div>
                    );
                case 2:
                    return (
                        <div>
                            <div className={'Detail-View'}>
                                <StudentbeginnersEducation jahre={sta}/>
                            </div>
                            <div className={'Color-Legend'}>
                                <ColorLegend categories={this.props.categories} colorA={this.props.color1}
                                             colorB={this.props.color2}
                                             categoryType={this.props.categoryType}></ColorLegend>
                            </div>
                        </div>
                    )
                case 3:
                    return (
                        <div className={'Detail-View'}>
                            <HZBQualification jahre={sta}/>
                        </div>

                    )
            }
        } else if (this.props.studentStatus == 1) {
            switch (this.props.categoryType) {
                case 0:
                    return (

                        <div>
                            <div className={'Detail-View'}>
                                <StudentsGender jahre={sta}/>
                            </div>
                            <div className={'Color-Legend'}>
                                <ColorLegend categories={this.props.categories} colorA={this.props.color1}
                                             colorB={this.props.color2}
                                             categoryType={this.props.categoryType}></ColorLegend>
                            </div>
                        </div>
                    );
                case 1:
                    return (


                        <div>
                            <div className={'Detail-View'}>
                                <StudentsNationality jahre={sta}/>
                            </div>
                            <div className={'Color-Legend'}>
                                <ColorLegend categories={this.props.categories} colorA={this.props.color1}
                                             colorB={this.props.color2}
                                             categoryType={this.props.categoryType}></ColorLegend>
                            </div>
                        </div>
                    );
                case 2:
                    return (
                        <div>
                            <div className={'Detail-View'}>
                                <StudentsEducation jahre={sta}/>
                            </div>
                            <div className={'Color-Legend'}>
                                <ColorLegend categories={this.props.categories} colorA={this.props.color1}
                                             colorB={this.props.color2}
                                             categoryType={this.props.categoryType}></ColorLegend>
                            </div>
                        </div>

                    )
            }
        }

    }
}




// @ts-ignore
const StudentbeginnersGender = ({jahre}) => (
    <div>

    <div className={'year-component'}>
        <div className={'headers-detail-view'}>
        <h1 className={'header'}>Geschlecht</h1>
        <h2 className={'subheader'}>Studienanfangende</h2>
        </div>
        {jahre.map((jahr: any) => (
            <YearComponentGender
                studienjahr={jahr.studienjahr}
                zahlA={jahr.maennlichZahl}
                prozentA={jahr.maennlichProzent}
                zahlB={jahr.weiblichZahl}
                prozentB={jahr.weiblichProzent}
                gesamtZahl={jahr.gesamtZahl}
                color = {jahr.color}/>
        ))}
            </div>

    </div>
);

// @ts-ignore
const StudentbeginnersNationality = ({jahre}) => (

    <div>
    <div className={'year-component'}>
        <div className={'headers-detail-view'}>
        <h1 className={'header'}>Nationalität</h1>
        <h2 className={'subheader'}>Studienanfangende</h2>
        </div>
    <div className={'year-component'}>
        {jahre.map((jahr: any) => (
            <YearComponentNationality
                studienjahr={jahr.studienjahr}
                zahlA={jahr.inlaendischZahl}
                prozentA={jahr.inlaendischProzent}
                zahlB={jahr.auslaendischZahl}
                prozentB={jahr.auslaendischProzent}
                gesamtZahl={jahr.gesamtZahl}
                color={jahr.color}/>
        ))}
    </div>
    </div>
    </div>

);

// @ts-ignore
const StudentbeginnersEducation = ({jahre}) => (
    <div>
    <div className={'year-component'}>
        <div className={'headers-detail-view'}>
        <h1 className={'header'}>Bildungsherkunft</h1>
        <h2 className={'subheader'}>Studienanfangende</h2>
        </div>
    <div className={'year-component'}>
        {jahre.map((jahr: any) => (
            <YearComponentEducation
                studienjahr={jahr.studienjahr}
                zahlA={jahr.inlaendischZahl}
                prozentA={jahr.inlaendischProzent}
                zahlB={jahr.auslaendischZahl}
                prozentB={jahr.auslaendischProzent}
                gesamtZahl={jahr.gesamtZahl}
                color={jahr.color}/>
        ))}
    </div>
    </div>
    </div>
)

// @ts-ignore
const StudentsGender = ({jahre}) => (
    <div>
    <div className={'year-component'}>
        <div className={'headers-detail-view'}>
        <h1 className={'header'}>Geschlecht</h1>
        <h2 className={'subheader'}>Studierende</h2>
        </div>
    <div className={'year-component'}>
        {jahre.map((jahr: any) => (
            <YearComponentGender
                studienjahr={jahr.studienjahr}
                zahlA={jahr.maennlichZahl}
                prozentA={jahr.maennlichProzent}
                zahlB={jahr.weiblichZahl}
                prozentB={jahr.weiblichProzent}
                gesamtZahl={jahr.gesamtZahl}
            color={jahr.color}/>
        ))}
    </div>
    </div>
    </div>
);

// @ts-ignore
const StudentsNationality = ({jahre}) => (
    <div>
    <div className={'year-component'}>
        <div className={'headers-detail-view'}>
        <h1 className={'header'}>Nationalität</h1>
        <h2 className={'subheader'}>Studierende</h2>
        </div>
    <div className={'year-component'}>
        {jahre.map((jahr: any) => (
            <YearComponentNationality
                studienjahr={jahr.studienjahr}
                zahlA={jahr.inlaendischZahl}
                prozentA={jahr.inlaendischProzent}
                zahlB={jahr.auslaendischZahl}
                prozentB={jahr.auslaendischProzent}
                gesamtZahl={jahr.gesamtZahl}
                color={jahr.color}/>
        ))}
    </div>
    </div>
    </div>

);


// @ts-ignore
const StudentsEducation = ({jahre}) => (
    <div>
    <div className={'year-component'}>
        <div className={'headers-detail-view'}>
        <h1 className={'header'}>Bildungsherkunft</h1>
        <h2 className={'subheader'}>Studierende</h2>
        </div>
    <div className={'year-component'}>
        {jahre.map((jahr: any) => (
            <YearComponentEducation
                studienjahr={jahr.studienjahr}
                zahlA={jahr.inlaendischZahl}
                prozentA={jahr.inlaendischProzent}
                zahlB={jahr.auslaendischZahl}
                prozentB={jahr.auslaendischProzent}
                gesamtZahl={jahr.gesamtZahl}
                color={jahr.color}/>
        ))}
    </div>
    </div>
    </div>
)

// @ts-ignore
const HZBQualification = ({jahre}) =>(
    <div>

    <div className={'year-component'}>
        <div className={'headers-detail-view'}>
            <h1 className={'header'}>Hochschulzugangsberechtigung</h1>
            <h2 className={'subheader'}>Studienanfangende</h2>
        </div>
        <div className={'year-component'}>
            {jahre.map((jahr: any) => (
               <YearComponentHzb
                   studienjahr={jahr.studienjahr}
                   abiturZahl={jahr.AbiturZahl}
                   abiturProzent={jahr.AbiturProzent}
                   fhReifeZahl={jahr.FHZahl}
                   fhReifeProzent={jahr.FHProzent}
                   berufZahl={jahr.BerufZahl}
                   berufProzent={jahr.BerufProzent}
                   auslaendischZahl={jahr.auslaendischZahl}
                   auslaendischProzent={jahr.auslaendischProzent}
                   gesamtZahl={jahr.gesamtZahl}
                   color={jahr.color}/>
            ))}
        </div>
    </div>
    </div>
)

