import React, { Component } from "react";
import Timeline from "../shared/Timeline";
import ReadData from "./../../ReadData";
import ReactTooltip from "react-tooltip";


const studentStatus = (student: number) => {
  if (student == 0) {
    return (
      <div>
        <h2>Studienanfangende</h2>
      </div>
    );
  }
  if (student == 1) {
    return (
      <div>
        <h2>Studierende</h2>
      </div>
    )
  }
}


export interface Props{
    year: number,
    color: string,
    zahlA: number,
    zahlB: number,
    zahlC: number,
    zahlD: number,
    prozentA: number,
    prozentB: number,
    prozentC: number,
    prozentD: number,
    gesamtZahl: number,
}
const dataService = new ReadData();

export class CompareViewHzb extends Component<Props> {
  constructor(props: Props) {
    super(props);

  }



  componentDidMount() {


  }



  render() {
    return (
        <div>
            <a  className={'tooltip-compare'}
                data-for={"main"}
                data-tip={"Allg. HZB Prozent: " + this.props.prozentA + "%" +
                "<br/>" + "FH-Reife Prozent: " + this.props.prozentB + "%" +
                "<br/>" + "Berufl. Qualifiziert: " + this.props.prozentC + "%" +
                "<br />" + "Ausl. HZB: "+ this.props.prozentA + "%" +
                "<br/>Allg. HZB: " + this.props.zahlA +
                "<br/>FH-Reife: " + this.props.zahlB +
                "<br/>Berufl. Qualifiziert: " + this.props.zahlC +
                "<br/>Ausl. HZB: " + this.props.zahlD +
                "<br/>Gesamt: " + this.props.gesamtZahl


                }
                data-iscapture={"true"}>

                <ReactTooltip
                    id={"main"}
                    multiline={true}
                />
                <div className={'compare-view'} style={{backgroundColor: this.props.color}}>

                    <div className={'subheader-compare-view'}></div>

                </div>
            </a>
        </div>
    );
  }
}

export default CompareViewHzb;
