import React from 'react';
import CustomSlider from "../../shared/CustomSlider";
import ColorLegend from "../../shared/ColorLegend";
import {TextBlock} from "../TextComponents/TextBlock";
import './CategoryType.scss';
import TextHeader from "../TextComponents/Text-Header";
import YearState from "../../shared/YearState";

export interface Props {
  color1: string;
  color2: string;
  textType: number;
  categoryType: number;
  category: number;
  year: number;
  categories: string[];
  categoriesText: string[];
}



export class CategoryType extends React.Component<Props, {category: string, categoryText: string, colorA: string, colorB: string, currentCategoryColor: string}> {
  constructor(props: Props) {
    super(props);
    this.state = {
        category: '',
        categoryText: '',
        colorA: this.props.color1,
        colorB: this.props.color2,
        currentCategoryColor: this.props.color1
    };
  }


  componentDidMount() {
      this.setCategoriesForButton();
  }


    setCategoriesForButton(){
      if (this.props.categoryType === 0) {
          this.setState({category: this.props.categories[this.props.category], categoryText: this.props.categoriesText[this.props.category]})
      }
      else if (this.props.categoryType === 1) {

          this.setState({category: this.props.categories[this.props.category], categoryText: this.props.categoriesText[this.props.category]})
      }

      else if (this.props.categoryType === 2){

          this.setState({category: this.props.categories[this.props.category], categoryText: this.props.categoriesText[this.props.category]})
      }
      else if (this.props.categoryType === 3) {

      }
  }

    checkText = (textType: number, category: number, categoryType: number) => {


        switch (textType) {
            case 0:

                // stelle Frage
                return this.checkTextBlock(category, categoryType);;
            case 1:
                // Geschlecht Page
                return (
                    <div>
                        <h2>Studienanfangende</h2>
                    </div>
                );
            case 2:
                // Nationalitaet
                return (
                    <div>
                        <h2>Studienanfangende</h2>
                    </div>
                );
            case 3:
                // Bildungsherkunft
                return (
                    <div>
                        <h2>Studienanfangende</h2>
                    </div>
                );
            case 4:
                // HZB Frage
                return (
                    <div>
                        <h2>Mit welcher Hochschulzugangsberechtigung</h2>
                        <h2>würdest du Medieninformatik studieren?</h2>
                    </div>
                );

            case 5:
                return (
                    <div>
                        <h2>Studienanfangende</h2>
                    </div>
                );
            //HZB Anzeige


        }
    }


    checkTextBlock = (category: number, categoryType: number) => {
        switch (categoryType){
            case 0:
                // Geschlecht

                return (
                    <div className={'Interactive-Text'}>
                        <div className={'First-Line'}>
                            <h2>Wie viele</h2>

                            <h2 className={'Category-Button'} style={{color: this.state.currentCategoryColor}} onClick={this.changeType.bind(this)}>{this.state.categoryText}</h2>

                            <h2>haben im Jahr</h2>
                            <div className={'dropdown'}>
                                <YearState color={this.state.currentCategoryColor}/>
                            </div>
                        </div>
                        <div className={'Second-Line'}>
                            <h2>an der Hochschule Bremen angefangen Medieninformatik zu studieren?</h2>
                            <h2> </h2>
                        </div>
                    </div>
                ) ;
            case 1:
                // Nationalität
                return (
                    <div className={'Interactive-Text'}>
                        <div className={'First-Line'}>
                            <h2>Wie viele Menschen</h2>

                            <h2 className={'Category-Button'} style={{color: this.state.currentCategoryColor}} onClick={this.changeType.bind(this)}>{this.state.categoryText}</h2>

                            <h2>Staatsangehörigkeit</h2>
                        </div>
                        <div className={'Second-Line'}>
                            <h2> haben im Jahr </h2>
                            <div className={'dropdown'}>
                                <YearState color={this.state.currentCategoryColor} />
                            </div>
                            <h2>an der Hochschule Bremen </h2>

                        </div>
                        <div className={'Third-Line'}>
                            <h2>angefangen  Medieninformatik zu studieren?</h2>
                        </div>
                    </div>
                );
            case 2:
                // Bildungsherkunft

                return(
                    <div className={'Interactive-Text'}>
                        <div className={'First-Line'}>
                            <h2>Wie viele Menschen haben im Jahr</h2>
                            <div className={'dropdown'}>
                                <YearState color={this.state.currentCategoryColor} />
                            </div>
                        </div>


                        <h2 className={'Category-Button'} style={{color: this.state.currentCategoryColor}} onClick={this.changeType.bind(this)}>{this.state.categoryText}</h2>


                        <div className={'Second-Line'}>
                            <h2>an der Hochschule Bremen angefangen Medieninformatik zu studieren?</h2>
                        </div>
                    </div>
                );
            case 3:
                //HZB
                return (
                    <div className={'Interactive-Text'}>
                        <div className={'First-Line'}>
                            <h2>Mit welcher Hochschulzugangsberechtigung</h2>

                        </div>
                        <div className={'Second-Line'}>
                            <h2>würdest du Medieninformatik studieren?</h2>
                        </div>
                    </div>
                );
        }
    }




    changeType(){
        this.setState(currentState =>
        {
            if (currentState.category === this.props.categories[0]){
                if (this.state.colorA === this.props.color1) {
                    return {category: this.props.categories[1], categoryText: this.props.categoriesText[1], colorA: this.props.color2, colorB: this.props.color1, currentCategoryColor: this.props.color2 }
                }
                else if (this.state.colorA === this.props.color2) {
                    return  {category: this.props.categories[1], categoryText: this.props.categoriesText[1], colorA: this.props.color1, colorB: this.props.color2, currentCategoryColor: this.props.color1}
                }
            }
            else {
                if (this.state.colorA === this.props.color1) {
                    return {category: this.props.categories[0], categoryText: this.props.categoriesText[0], colorA: this.props.color2, colorB: this.props.color1, currentCategoryColor: this.props.color2}
                }
                else if (this.state.colorA === this.props.color2) {
                    return  {category: this.props.categories[0], categoryText: this.props.categoriesText[0], colorA: this.props.color1, colorB: this.props.color2, currentCategoryColor: this.props.color1}
                }

            }
        });


    }





    render() {
    return (
        <div className={'Landing-Page'}>
            <div className={'Text'}>
            <div className={'Text-Header'}>
                <TextHeader textType={this.props.textType}/>
            </div>
                <div className={'Text-Block-With-Button'}>
                    <div className={'TextBlock'}>
                        {this.checkText(this.props.textType, this.props.category, this.props.categoryType)}
                        {/*<button onClick={this.changeType.bind(this)}>{this.state.category}</button>*/}
                    </div>
                    <CustomSlider color1={this.state.colorB} color2={this.state.colorA} textType={this.props.textType}
                                  categoryType={this.props.categoryType} category={this.props.category}
                                  year={this.props.year}/>
                </div>
            </div>

            <div className={'Color-Legend'}>
                <ColorLegend categories={this.props.categories} colorA={this.props.color2} colorB={this.props.color1} categoryType={this.props.categoryType}></ColorLegend>
            </div>
        </div>
     );
  }


}

export default CategoryType;
