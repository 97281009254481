import React from 'react';
import logo from './logo.svg';
import './App.scss';
import Page from './app/frontend/gestaltung-tab/components/Page'
import ColorValues from "./app/frontend/gestaltung-tab/shared/ColorValues";

import ReactFullpage from '@fullpage/react-fullpage';
import DatavisualizationCategory from "./app/frontend/gestaltung-tab/components/DatavisualizationCategory";
import {LandingPage} from "./app/frontend/gestaltung-tab/components/LandingPage";

const colors = new ColorValues();

function App() {
    return (

        <div className="App">

                            <DatavisualizationCategory color1={colors.baseColorWomen} color2={colors.baseColorMen} textType={0} categoryType={0} category={0} year={2013}></DatavisualizationCategory>
                            <DatavisualizationCategory color1={colors.baseColorNationalityDomestic} color2={colors.baseColorNationalityForeign} textType={0} categoryType={1} category={1} year={2013}></DatavisualizationCategory>
                            <DatavisualizationCategory color1={colors.baseColorEducationDomestic} color2={colors.baseColorEducationForeign} textType={0} categoryType={2} category={1} year={2013}/>
            <LandingPage colorA={colors.baseColorMen} colorB={colors.baseColorWomen} textType={0} categoryType={3} category={0} year={2013}/>

        </div>
    );
}

export default App;
