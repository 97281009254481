
export default class ReadData {
    private API_URL = process.env.REACT_APP_API_URI;


    getStudentsAllGender = async () => {
        console.log("API = ",this.API_URL)
        const response = await fetch(`${this.API_URL}/students/allGender`, {mode:'cors'});
        const body = await response.json();
        return body;
    }


    getStudentsAllNationality = async () => {
        const response = await fetch(`${this.API_URL}/students/allNationality`, {mode:'cors'});
        const body = await response.json();
        return body;
    }

    getStudentsAllEducationBackground = async () => {
        const response = await fetch(`${this.API_URL}/students/allEducationBackground`, {mode:'cors'});
        const body = await response.json();
        return body;
    }

    getSpecificYearGenderStudents = async (year: number) => {
        const response = await fetch(`${this.API_URL}/students/getSpecificYearGender/` + year, {mode:'cors'});
        const body = await response.json();
        return body;
    }

    getSpecificYearNationalityStudents = async (year: number) => {
        const response = await fetch(`${this.API_URL}/students/getSpecificYearNationality/` + year, {mode:'cors'});
        const body = await response.json();
        return body;
    }

    getSpecificYearEducationBackgroundStudents = async (year: number) => {
        const response = await fetch(`${this.API_URL}/students/getSpecificYearEducationBackground/` + year, {mode:'cors'});
        const body = await response.json();
        return body;
    }


    getStudentbeginnersAllGender = async () => {
        const response = await fetch(`${this.API_URL}/studentbeginners/allGender`, {mode:'cors'});
        const body = await response.json();
        console.log(body);
        return body;
    }

    getStudentbeginnersAllNationality = async () => {
        const response = await fetch(`${this.API_URL}/studentbeginners/allNationality`, {mode:'cors'});
        const body = await response.json();
        return body;
    }

    getStudentbeginnersAllEducationBackground = async () => {
        const response = await fetch(`${this.API_URL}/studentbeginners/allEducationBackground`, {mode:'cors'});
        const body = await response.json();
        return body;
    }

    getAllUniversityQualification = async () => {
        const response = await fetch(`${this.API_URL}/studentbeginners/allUniversityQualification`, {mode:'cors'});
        const body = await response.json();
        return body;
    }

    getSpecificYearGenderSB = async (year: number) => {
        const response = await fetch(`${this.API_URL}/studentbeginners/getSpecificYearGender/` + year, {mode:'cors'});
        const body = await response.json();
        return body;
    }

    getSpecificYearNationalitySB = async (year: number) => {
        const response = await fetch(`${this.API_URL}/studentbeginners/getSpecificYearNationality/` + year, {mode:'cors'});
        const body = await response.json();
        return body;
    }

    getSpecificYearEducationSB = async (year: number) => {
        const response = await fetch(`${this.API_URL}/studentbeginners/getSpecificYearEducationBackground/` + year, {mode:'cors'});
        const body = await response.json();
        return body;
    }

    getSpecificYearHZB = async (year: number) => {
        const response = await fetch(`${this.API_URL}/studentbeginners/getSpecificYearUniversityQualification/` +year, {mode:'cors'});
        const body = await response.json();
        return body;
    }
}
