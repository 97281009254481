import React from "react";
import ChangeColors from "../shared/ChangeColors";
import ColorValues from "../shared/ColorValues";
import ReactTooltip from "react-tooltip";
import './CompareView.scss'


export interface Props{
    year: number,
    color: string,
    studentStatus: number,
    zahlA: number,
    zahlB: number,
    prozentA: number,
    prozentB: number,
    gesamtZahl: number,
    categoryType: number,
}

const studentStatus = (student: number) => {
    if (student == 0) {
        return (

                <h2>Studienanfangende</h2>

        );
    }
    if (student == 1) {
        return (

                <h2>Studierende</h2>

        )
    }
}



export default class CompareView extends React.Component<Props, any> {
    constructor(props: Props) {
        super(props);
        this.checkCategory()
    }


    categoryOne = '';
    categoryTwo = '';
    checkCategory = () => {
        if (this.props.categoryType == 0) {
            this.categoryOne = 'Männlich';
            this.categoryTwo = 'Weiblich';
        }

        else if (this.props.categoryType == 1) {
            this.categoryOne = 'inländisch';
            this.categoryTwo = 'ausländisch';
        }
        else if (this.props.categoryType == 2) {
            this.categoryOne = 'bildungsinländisch';
            this.categoryTwo = 'bildungsausländisch';
        }
    }

    render() {
        return (
            <div>
            <a  className={'tooltip-compare'}
                data-for={"main"}
                data-tip={this.categoryOne +" Prozent: " + this.props.prozentA + "%" +
                "<br/>" + this.categoryTwo +" Prozent: " + this.props.prozentB + "%" +
                "<br/>" + this.categoryOne +": " + this.props.zahlA +
                "<br />" + this.categoryTwo + ": "+ this.props.zahlB +
                "<br/>Gesamt: " + this.props.gesamtZahl}
                data-iscapture={"true"}>

                <ReactTooltip
                    id={"main"}
                    multiline={true}
                />
                <div className={'compare-view'} style={{backgroundColor: this.props.color}}>

                    <div className={'subheader-compare-view'}>{studentStatus(this.props.studentStatus)}</div>

                </div>
            </a>
            </div>
        );
    }
}
