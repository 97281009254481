export default class ColorValues{

    baseColorMen = '#f94144';
    baseColorWomen = '#f9c74f';

    baseColorNationalityDomestic = '#f3722c';
    baseColorNationalityForeign = '#90be6d';

    baseColorEducationDomestic = '#f8961e';
    baseColorEducationForeign = '#43aa8b';

    baseColorLinks = '#577590';

    hzbColorYear2013 = '#be994e';
    hzbColorYear2014 = '#c0984d';
    hzbColorYear2015 = '#b79b52';
    hzbColorYear2016 = '#c2944b';
    hzbColorYear2017 = '#c2904e';
    hzbColorYear2018 = '#c5914b';
    hzbColorYear2019 = '#bf924d';


}
