import React, {Component} from 'react';
import Page from "./Page";

export interface Props{
    category: number;
    color1: string;
    color2: string;
    textType: number;
    categoryType: number;
    year: number;
}

export default class DatavisualizationCategory extends Component<Props, { categories: string[], categoriesText: string[] }>{

    categories: string[] = [];
    categoriesText: string[] = [];

    constructor(props: Props) {
        super(props);
        this.state = {
            categories: [],
            categoriesText: [],
        }

    }

    componentDidMount() {
        this.setCategories();
    }


    setCategories(){
        if (this.props.categoryType === 0) {
            this.categories[0] = 'Männer';
            this.categories[1] = 'Frauen';
            this.categoriesText[0] = 'Männer';
            this.categoriesText[1] = 'Frauen';
            this.addArrayToState(this.categories, this.categoriesText);


        }
        else if (this.props.categoryType === 1) {
            this.categories[0] = 'inländisch';
            this.categories[1] = 'ausländisch';
            this.categoriesText[0] = 'mit der deutschen';
            this.categoriesText[1] = 'ohne die deutsche';
            this.addArrayToState(this.categories, this.categoriesText);
        }

        else if (this.props.categoryType === 2){
            this.categories[0] = 'bildungsinländisch';
            this.categories[1] = 'bildungsausländisch';
            this.categoriesText[0] = 'mit der deutschen Staatsangehörigkeit und einem deutschen Abschluss';
            this.categoriesText[1] = 'ohne die deutsche Staatsangehörigkeit und ohne einem deutschen Abschluss';
            this.addArrayToState(this.categories, this.categoriesText);
        }
        else if (this.props.categoryType === 3) {
            this.categories[0] = 'Männer';
            this.categories[1] = 'Frauen';
        }
    }


    addArrayToState(cate: string[], categoriesText: string[]){
        this.setState({categories: [...cate], categoriesText: [...categoriesText]}, () =>{
            console.log("categories = ", this.state.categories);
            console.log("categoriesText = ", this.state.categoriesText);
        });


    }

    render() {
        return (
            <div>
                <Page color1={this.props.color1} color2={this.props.color2} textType={this.props.textType} categoryType={this.props.categoryType} category={this.props.category} year={this.props.year} categories={this.state.categories} categoriesText={this.state.categoriesText}/>
            </div>
        );
    }
}
