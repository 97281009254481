import React from "react";
import ChangeColors from "../../shared/ChangeColors";
import ColorValues from "../../shared/ColorValues";
import ReactTooltip from "react-tooltip";
import './YearComponent.scss';




export interface Props {
    studienjahr: number,
    abiturZahl: number,
    abiturProzent: number,
    fhReifeZahl: number,
    fhReifeProzent: number,
    berufZahl: number,
    berufProzent: number,
    auslaendischZahl: number,
    auslaendischProzent: number,
    gesamtZahl: number,
    color: string,

}

const colors = new ColorValues();
const changeColor = new ChangeColors();

export default class YearComponentHzb extends React.Component<Props>{
    constructor(props: Props) {
        super(props);
    }

    mixColor = () =>{

    }




    render() {
        return (
            <div className={'one-year'} style={{backgroundColor: this.props.color}}>
                <div className={'studyyear'}>
                    {this.props.studienjahr}

                </div>
                <a className={'tooltip-year'}
                   data-for={"main"}
                   data-tip={"Allg. HZB Prozent: " + this.props.abiturProzent + "%" +
                   "<br/>FH-Reife Prozent: " + this.props.fhReifeProzent + "%" +
                   "<br/>Berufl. Qualifiziert Prozent: " + this.props.berufProzent + "%" +
                   "<br />ausl. HZB/Studienkolleg Prozent: " + this.props.auslaendischProzent + "%" +
                   "<br/> Allg. HZB: " + this.props.abiturZahl +
                   "<br/>FH-Reife: " + this.props.fhReifeZahl +
                   "<br/>Berufl. Qualifiziert: " + this.props.berufZahl +
                   "<br/>ausl. HZB/Studienkolleg: " + this.props.auslaendischZahl +
                   "<br/>Gesamt: " + this.props.gesamtZahl +
                   "<br/>Farbe: " + this.props.color}
                   data-iscapture={"true"}>

                    <ReactTooltip
                        id={"main"}
                        multiline={true}
                    />

                </a>

            </div>

        );
    }
}
