import React, {useState} from "react";
import './ColorLegend.scss';

export interface Props{
    colorA: string,
    colorB: string,
    categoryType: number,
    categories: string[],
}

export default class ColorLegend extends React.Component<Props, any>{
    constructor(props: Props) {
    super(props);
}


setHZBColors() {
    if (this.props.categoryType == 3) {

        return (
            <div>
                <div className={'category-text'}>{this.props.categories[0]}</div>
                <div className={"ColorBoxA"} style={{backgroundColor: "#f94144"}}></div>
                <div className={'category-text'}>{this.props.categories[1]}</div>
                <div className={"ColorBoxB"} style={{backgroundColor: "#43aa8b"}}></div>
            </div>
        )
    }
}

    render() {
    return (
        <div className={"ColorLegend"}>
            <div className={'categories'}>
                <div className={'category-text'}> {this.props.categories[0]}</div>
                <div className={"ColorBoxA colorBoxes"} style={{backgroundColor: this.props.colorB}}>
                </div>
            </div>
            <div className={'categories'}>
                <div className={'category-text'}>{this.props.categories[1]}</div>
                <div className={"ColorBoxB colorBoxes"} style={{backgroundColor: this.props.colorA}}></div>
                <div>{this.setHZBColors()}</div>
            </div>
        </div>
    )
}


}
