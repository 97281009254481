import React, {Component} from "react";
import List from '@material-ui/core/List';
import {ListItem, ListItemText, withStyles} from "@material-ui/core";
import CompareView from "./CompareView";
import ReadData from "./../../ReadData";
import ChangeColors from "../shared/ChangeColors";
import ColorValues from "../shared/ColorValues";
import './TimelineComponent.scss';
import CompareViewHzb from "./CompareViewHzb";


const dataService = new ReadData();
const changeColor = new ChangeColors();
const colors = new ColorValues();


/*const styles = makeStyles( {
    root: {
        font-family: 'Karla',
        font-size: '24px',
        font-weight: 350,

    }
});*/



export interface Props {
    categoryType: number,
    studentStatus: number,
    categoryHeader: number,
}

export default class TimelineComponent extends React.Component<Props, any>{
    constructor(props: Props) {
        super(props);

        this.state = {
            chartData: [],
            selectedYear: 2013,
            color: '',
        }
    }

    componentDidMount() {
        this.getDataForSpecificYear(this.state.selectedYear);

    }

    getDataForSpecificYear(year: number) {
        switch (this.props.categoryType){
            case 0:
                if (this.props.studentStatus == 0){
                    dataService.getSpecificYearGenderSB(year)
                        .then( data => {
                            this.setState({chartData: data, color: this.mixColorGender(data[0].weiblichProzent)})

                        })
                        .catch(err => console.log(err));
                }
                else if (this.props.studentStatus == 1) {
                    dataService.getSpecificYearGenderStudents(year)
                        .then(data => {
                            this.setState({chartData: data, color: this.mixColorGender(data[0].weiblichProzent)})
                        })
                        .catch(err => console.log(err));
                }
                break;
            case 1:
                if (this.props.studentStatus == 0){
                    dataService.getSpecificYearNationalitySB(year)
                        .then(data => {
                            this.setState({chartData: data, color: this.mixColorNationality(data[0].auslaendischProzent)})

                        })
                        .catch(err => console.log(err));
                }
                else if (this.props.studentStatus == 1) {
                    dataService.getSpecificYearNationalityStudents(year)
                        .then(data => {
                            this.setState({chartData: data, color: this.mixColorNationality(data[0].auslaendischProzent)})
                        })
                        .catch(err => console.log(err));
                }
                break;

            case 2:
                if (this.props.studentStatus == 0){
                    dataService.getSpecificYearEducationSB(year)
                        .then(data => {
                            this.setState({chartData: data, color: this.mixColorEducation(data[0].auslaendischProzent)});
                        })
                        .catch(err => console.log(err));
                }
                else if (this.props.studentStatus == 1) {
                    dataService.getSpecificYearEducationBackgroundStudents(year)
                        .then(data => {
                            this.setState({chartData: data, color: this.mixColorEducation(data[0].auslaendischProzent)})
                        })
                        .catch(err => console.log(err));
                }
                break;

            case 3:
                dataService.getSpecificYearHZB(year)
                    .then(data => {
                        this.setState({chartData: data, color: this.getColorHzb(year)})
                    })
                    .catch(err => console.log(err) );

        }
    }



    mixColorGender = (prozent: number) =>{
        if (prozent === 100) {
            prozent--;
            return changeColor.changeColor(colors.baseColorMen, colors.baseColorWomen, (prozent/100) );
        }
        else if (prozent === 0) {
            prozent++;
            return changeColor.changeColor(colors.baseColorMen, colors.baseColorWomen, (prozent/100) );
        }
        else {
            return changeColor.changeColor(colors.baseColorMen, colors.baseColorWomen, (prozent/100) );
        }
    }

    mixColorNationality = (prozent: number) =>{
        if (prozent === 100) {
            prozent--;
            return changeColor.changeColor(colors.baseColorNationalityDomestic, colors.baseColorNationalityForeign, (prozent/100) );
        }
        else if (prozent === 0) {
            prozent++;
            return changeColor.changeColor(colors.baseColorNationalityDomestic, colors.baseColorNationalityForeign, (prozent/100) );
        }
        else {
            return changeColor.changeColor(colors.baseColorNationalityDomestic, colors.baseColorNationalityForeign, (prozent/100) );
        }

    }

    mixColorEducation = (prozent: number) =>{
        if (prozent === 100) {
            prozent--;
            return changeColor.changeColor(colors.baseColorEducationDomestic, colors.baseColorEducationForeign, (prozent/100) );
        }
        else if (prozent === 0) {
            prozent++;
            return changeColor.changeColor(colors.baseColorEducationDomestic, colors.baseColorEducationForeign, (prozent/100) );
        }
        else {
            return changeColor.changeColor(colors.baseColorEducationDomestic, colors.baseColorEducationForeign, (prozent/100) );
        }

    }

    getColorHzb = (year: number) => {
        let color: string = '';
        switch (year) {
            case 2013:
                return color = colors.hzbColorYear2013;
            case 2014:
                return color = colors.hzbColorYear2014;
            case 2015:
                return color = colors.hzbColorYear2015;
            case 2016:
                return color = colors.hzbColorYear2016;
            case 2017:
                return color = colors.hzbColorYear2017;
            case 2018:
                return color = colors.hzbColorYear2018;
            case 2019:
                return color = colors.hzbColorYear2019;
        }
        return color;
    }

    handleListItemClick = (event: any, item: number) => {
        this.setState( {selectedYear: item});
        this.getColorHzb(item);
        this.getDataForSpecificYear(item);
    }

    checkHeader = () => {
        if (this.props.categoryHeader === 0) {
            if (this.props.categoryType === 0) {
                return (
                    <h1 className={'category-header'}>Geschlecht</h1>
                )
            }
            else if (this.props.categoryType === 1) {
                return (
                    <h1 className={'category-header'}>Nationalität</h1>
                )
            }
            else if (this.props.categoryType === 2) {
                return (
                    <h1 className={'category-header'}>Bildungsherkunft</h1>
                )
            }
            else if (this.props.categoryType === 3){
                return (
                    <h1 className={'category-header'}>Hochschulzugangsberechtigung</h1>
                )
            }
        }
        else{
            return (
                <h1></h1>
            )
        }
    }

    checkCategory = () => {
        if(this.state.chartData.length != 0){
        if (this.props.categoryType == 0) {
            return (


                <CompareView
                    year={this.state.selectedYear}
                    color={this.state.color}
                    studentStatus={this.props.studentStatus}
                    zahlA={this.state.chartData[0].maennlichZahl}
                    zahlB={this.state.chartData[0].weiblichZahl}
                    prozentA={this.state.chartData[0].maennlichProzent}
                    prozentB={this.state.chartData[0].weiblichProzent}
                    gesamtZahl={this.state.chartData[0].gesamtZahl}
                    categoryType={this.props.categoryType}/>

            );
        } else if (this.props.categoryType != 0 && this.props.categoryType < 3) {
            return (
                <CompareView
                    year={this.state.selectedYear}
                    color={this.state.color}
                    studentStatus={this.props.studentStatus}
                    zahlA={this.state.chartData[0].inlaendischZahl}
                    zahlB={this.state.chartData[0].auslaendischZahl}
                    prozentA={this.state.chartData[0].inlaendischProzent}
                    prozentB={this.state.chartData[0].auslaendischProzent}
                    gesamtZahl={this.state.chartData[0].gesamtZahl}
                    categoryType={this.props.categoryType}/>
            );
        }
        else if (this.props.categoryType === 3) {
            return(
                <CompareViewHzb
                    year={this.state.selectedYear}
                    color={this.state.color}
                    zahlA={this.state.chartData[0].AbiturZahl}
                    zahlB={this.state.chartData[0].FHZahl}
                    zahlC={this.state.chartData[0].BerufZahl}
                    zahlD={this.state.chartData[0].auslaendischZahl}
                    prozentA={this.state.chartData[0].AbiturProzent}
                    prozentB={this.state.chartData[0].FHProzent}
                    prozentC={this.state.chartData[0].BerufProzent}
                    prozentD={this.state.chartData[0].auslaendischProzent}
                    gesamtZahl={this.state.chartData[0].gesamtZahl}/>
            )
        }
    }
    }




    render() {
        return (
            <div className="timeline" style={{backgroundColor: this.state.color}}>
                {this.checkHeader()}
                <div className={'anchor'}>
                    {this.checkCategory()}
                </div>
                <List className="zeitstrahl">
                    <ListItem button
                              selected={this.state.selectedYear === 2013}
                              onClick={(event) => this.handleListItemClick(event, 2013)}>
                        <ListItemText classes={{}} className={'list-item-text'} primary="2013" />
                    </ListItem>

                    <ListItem button
                              selected={this.state.selectedYear === 2014}
                              onClick={(event) => this.handleListItemClick(event, 2014)}>
                        <ListItemText className={'list-item-text'}  primary="2014" />
                    </ListItem>

                    <ListItem button
                              selected={this.state.selectedYear === 2015}
                              onClick={(event) => this.handleListItemClick(event, 2015)}>
                        <ListItemText className={'list-item-text'} primary="2015" />
                    </ListItem>

                    <ListItem button
                              selected={this.state.selectedYear === 2016}
                              onClick={(event) => this.handleListItemClick(event, 2016)}>
                        <ListItemText className={'list-item-text'} primary="2016" />
                    </ListItem>

                    <ListItem button
                              selected={this.state.selectedYear === 2017}
                              onClick={(event) => this.handleListItemClick(event, 2017)}>
                        <ListItemText className={'list-item-text'} primary="2017" />
                    </ListItem>

                    <ListItem button

                              selected={this.state.selectedYear === 2018}
                              onClick={(event) => this.handleListItemClick(event, 2018)}>
                        <ListItemText className={'list-item-text'} primary="2018" />
                    </ListItem>

                    <ListItem button
                              selected={this.state.selectedYear === 2019}
                              onClick={(event) => this.handleListItemClick(event, 2019)}>
                        <ListItemText className={'list-item-text'} primary="2019" />
                    </ListItem>
                </List>


            </div>
        );
    }





}


