import React from 'react';
import Select from 'react-select';
import './../components/CategoryType/CategoryType.scss'

const years =[
    {value: 2013, label: 2013},
    {value: 2014, label: 2014},
    {value: 2015, label: 2015},
    {value: 2016, label: 2016},
    {value: 2017, label: 2017},
    {value: 2018, label: 2018},
    {value: 2019, label: 2019}
];




export interface Props{
    color: string;
}

class YearState extends React.Component<Props> {
    state = {
        selectedYear: null,
    };
    handleChange = (selectedYear: any) => {
        this.setState({ selectedYear }, () => {});
    };


    customStyles = {
        control: base => ({...base, width: '140px',
            border: 0,
            boxShadow: 'none',
            backgroundColor: 'none',
            fontFamily: 'Karla',
            fontSize: 40,
            color: this.props.color,
        }),
        option: styles => ({...styles,
            fontFamily: 'Karla',
            fontSize: 20,
            color: this.props.color,
        }),
        singleValue: styles => ({...styles,
            color: this.props.color,
            textShadow: '-3px 3px 4px rgba(0,0,0,0.75)' }),
        placeholder: styles => ({...styles,
            color: this.props.color,
            textShadow: '-3px 3px 4px rgba(0,0,0,0.75)' }),
        dropdownIndicator: styles => ({...styles,
            color: this.props.color,
            hover: this.props.color}),


    };
    render() {
        const { selectedYear } = this.state;

        return (
            <Select
                key={ this.props.color}
                styles={this.customStyles}

                value={selectedYear}
                onChange={this.handleChange}
                options={years}
                comments={{
                    IndicatorSeparator: () => null
                }}
                placeholder={years[0].label}
                defaultValue={years[0]}
            />
        );
    }
}

export default YearState;

