import {LandingPage} from "./LandingPage";
import DetailPage from "./DetailPage";
import TimelineComponent from "./TimelineComponent";
import React, {Component, useState} from "react";
import CustomSlider from "../shared/CustomSlider";
import ColorValues from "../shared/ColorValues";
import CategoryType from "./CategoryType/CategoryType";
import ReactFullpage from '@fullpage/react-fullpage';
import './Page.scss'
import {log} from "util";

const colors = new ColorValues();


export interface Props{
    color1: string;
    color2: string;
    textType: number;
    categoryType: number;
    category: number;
    year: number;
    categories: string[];
    categoriesText: string[];
}
export default class Page extends Component<Props, { categories: string[], categoriesText: string[] }>{
    categories: string[] = [];
    categoriesText: string[] = [];


    constructor(props: Props) {
        super(props);
       /* this.state = {
            categories: [],
            categoriesText: [],
        }
        */

        this.setCategories();
    }

    /*componentDidMount() {
        this.setCategories();
    }*/




    setCategories(){
        if (this.props.categoryType === 0) {
            this.categories[0] = 'Männer';
            this.categories[1] = 'Frauen';
            this.categoriesText[0] = 'Männer';
            this.categoriesText[1] = 'Frauen';
            this.addArrayToState(this.categories, this.categoriesText);


        }
        else if (this.props.categoryType === 1) {
            this.categories[0] = 'inländisch';
            this.categories[1] = 'ausländisch';
            this.categoriesText[0] = 'mit der deutschen';
            this.categoriesText[1] = 'ohne die deutsche';
            this.addArrayToState(this.categories, this.categoriesText);
        }

        else if (this.props.categoryType === 2){
            this.categories[0] = 'bildungsinländisch';
            this.categories[1] = 'bildungsausländisch';
            this.categoriesText[0] = 'mit der deutschen Staatsangehörigkeit und einem deutschen Absschluss';
            this.categoriesText[1] = 'ohne die deutsche Staatsangehörigkeit und ohne einem deutschen Abschluss';
            this.addArrayToState(this.categories, this.categoriesText);
        }
        else if (this.props.categoryType === 3) {
            this.categories[0] = 'Männer';
            this.categories[1] = 'Frauen';
        }
    }


    addArrayToState(cate: string[], categoriesText: string[]){
        this.setState({categories: [...cate], categoriesText: [...categoriesText]}, () =>{});


    }







    render() {
       return (
           <div>

          {/*     <ReactFullpage
                   scrollingSpeed={1000}  Options here
                   render={({ state, fullpageApi }) => {
                       return (
                           <ReactFullpage.Wrapper>*/}
                               {/* <div className="section">
                                <p>Section 1 (welcome to fullpage.js)</p>
                                <button onClick={() => fullpageApi.moveSectionDown()}>
                                    Click me to move down
                                </button>
                            </div>
                            <div className="section">
                                <p>Section 2</p>
                            </div> */}


                               <div className="LandingPageArea section">
                                   <CategoryType color1={this.props.color2} color2={this.props.color1} textType={this.props.textType} categoryType={this.props.categoryType}
                                                 category={this.props.category} categories={this.categories} categoriesText={this.categoriesText} year={this.props.year} />

                               </div>

                               <div className={'DetailPageArea section'}>
                                   <DetailPage categories={this.categories} color1={this.props.color1} color2={this.props.color2} studentStatus={0} categoryType={this.props.categoryType}></DetailPage>
                               </div>
                               <div className={'DetailPageArea section'}>
                                   <DetailPage categories={this.categories} color1={this.props.color1} color2={this.props.color2} studentStatus={1} categoryType={this.props.categoryType}></DetailPage>
                               </div>
                               <div className={'ComparePageArea section'}>

                                       <div className={'Timeline left'}>

                                           <TimelineComponent categoryType={this.props.categoryType} studentStatus={1} categoryHeader={0}/>
                                       </div>
                                       <div className={'Timeline right'}>
                                           <TimelineComponent categoryType={this.props.categoryType} studentStatus={0} categoryHeader={1}/>
                                       </div>
                               </div>
{/*
                               <div className="LandingPageArea section">
                                   <CategoryType color1={colors.baseColorNationalityDomestic}
                                                 color2={colors.baseColorNationalityForeign}
                                                 textType={0}
                                                 categoryType={1}
                                                 category={0}
                                                 year={2013}/>

                               </div>

                               <div className={'DetailPageArea section'}>
                                   <DetailPage categories={} color1={this.props.color1} color2={this.props.color2}  studentStatus={0} categoryType={1}></DetailPage>
                               </div>

                               <div className={'DetailPageArea section'}>
                                   <DetailPage categories={} color1={this.props.color1} color2={this.props.color2}  studentStatus={1} categoryType={1}></DetailPage>
                               </div>

                               <div className={'ComparePageArea section'}>

                                   <div className={'Timeline left'}>
                                       <TimelineComponent categoryType={1} studentStatus={1} categoryHeader={0}/>
                                   </div>
                                   <div className={'Timeline right'}>
                                       <TimelineComponent categoryType={1} studentStatus={0} categoryHeader={1}/>
                                   </div>
                               </div>


                               <div className="LandingPageArea section">
                                   <CategoryType
                                       color1={colors.baseColorEducationDomestic}
                                       color2={colors.baseColorEducationForeign}
                                       textType={0}
                                       categoryType={2}
                                       category={0}
                                       year={2013}/>

                               </div>

                               <div className={'DetailPageArea section'}>
                                   <DetailPage categories={} color1={this.props.color1} color2={this.props.color2}  studentStatus={0} categoryType={2}></DetailPage>
                               </div>

                               <div className={'DetailPageArea section'}>
                                   <DetailPage categories={} color1={this.props.color1} color2={this.props.color2}  studentStatus={1} categoryType={2}></DetailPage>
                               </div>

                               <div className={'ComparePageArea section'}>
                                   <div>

                                       <div className={'Timeline left'}>
                                           <TimelineComponent categoryType={2} studentStatus={1} categoryHeader={0}/>
                                       </div>
                                       <div className={'Timeline right'}>
                                           <TimelineComponent categoryType={2} studentStatus={0} categoryHeader={1}/>
                                       </div>
                                   </div>
                               </div>
                               <div className={"LandingPageArea HZB section"}>
                                   <LandingPage colorA={colors.baseColorNationalityDomestic} colorB={colors.baseColorNationalityForeign}
                                                textType={4} categoryType={3} category={0} year={2013}/>
                               </div>

                               <div className={'DetailPageArea section'}>
                                   <DetailPage categories={} color1={this.props.color1} color2={this.props.color2} studentStatus={0} categoryType={3}></DetailPage>
                               </div>

                               <div className={'ComparePageHZB section'}>
                                   <TimelineComponent categoryType={3} studentStatus={0} categoryHeader={0}/>
                               </div>*/}
                  {/*         </ReactFullpage.Wrapper>
                       );
                   }}
               />

*/}


           </div>

       );
   }




}
